import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import logolight from "../../assets/images/logo-light.png";

class FooterLinks extends Component {
    render() {
        return (
            <React.Fragment>
        <section className="bg-primary py-3">
            <Container>
                <Row>
                  
                      
                        <div className="float-sm mt-4 mt-sm-1 ">
                            <p className="copyright-desc text-white mb-0 ">{new Date().getFullYear()} © Networker II - Soluções Informáticas Lda.</p>
                        </div>
                       
                   
                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default FooterLinks;