import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import FeatureBox from "./feature-box";

//Import Images
import feature1 from "../../assets/images/features/phc.png";
import feature2 from "../../assets/images/features/mobile.png";


class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Gestão rápida e eficaz" },
                { title : "Software sólido" },
                { title : "Personalizaável" },
                { title : "Suporte especializado" },
            ],
            smallFeatures2 : [
                { title : "Desenhadas á medida" },
                { title : "Fácil utilização" },
                 { title : "Liberdade de utilização" },
                  { title : "Online & Offline" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
        <section className="section" id="features">
            <Container>
            <SectionTitle
                subtitle="Sobre nós"
                title="Soluções IT a pensar no presente e no futuro"
                desc="A nossa missão é o aumento de resultados e produtividade através de soluções tecnológicas inovadoras"
            />               

                <Row>
                    <Col lg={5}>
                        <div>
                            <FeatureBox icon="bar-chart-2" title="Sistema de gestão PHC" smallFeatures={this.state.smallFeatures1} desc="Gestão sólida e eficaz e como principal distribuidor da PHC no Vale do Sousa, comprometemo-nos ao melhor serviço para melhor satisfazermos os nossos clientes.
" link="#" />
                        </div>
                    </Col>

                    <Col lg={5} sm={8} className="ml-lg-auto">
                        <Card className=" shadow-none mt-5 mt-lg-20">
                            
                            <CardBody >
                                <div >
                                    <img src={feature1} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                

                <Row className="mt-5 pt-5">
                    <Col lg={5} sm={8}>
                        <Card className=" shadow-none">
                            
                            <CardBody >
                                <div >
                                    <img src={feature2} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={5} className="ml-lg-auto">
                        <div className="mt-4 mt-lg-0">
                        <FeatureBox icon="smartphone" title="Aplicações Móveis" smallFeatures={this.state.smallFeatures2} desc="Desenhadas á sua medidas as nossas aplicações móveis permitem que tenha toda a gestão da sua empresa no bolso." link="#" />
                        </div>
                    </Col>

                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Features;