import React, { Component } from 'react';
import { Row } from "reactstrap";

//Import Blog Box
import CounterBox from "./counter-box";

class Counter extends Component {
    state = {
        counters: [
            { icon: "file", title: "Projetos", start: 10, end: 783 },
            { icon: "user-plus", title: "Clientes", start: 2, end: 500 },
            { icon: "user", title: "Parceiros PHC", start: 8, end: 150 },
            { icon: "smile", title: "Cafés tomados", start: 6, end: 583 },
        ],
    }
    render() {
        return (
            <React.Fragment>
                <Row id="counter">
                    <CounterBox counters={this.state.counters} />
                </Row>
            </React.Fragment>
        );
    }
}

export default Counter;