import React, { Component } from 'react';
import { Container, Row, Col, Form, Input, Button } from "reactstrap";

class Software extends Component {
    render() {
        return (
            <React.Fragment>
        <section className="section">
            <Container>
              
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Software;