import TextLoop from "react-text-loop";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col
} from "reactstrap";


//Import Images
import homeImg from "../../assets/images/home-img.png";


class Section extends Component {

    render() {
        return (

            <React.Fragment>
                <section className="hero-section" id="home">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="hero-wrapper mb-4">
                                    <p className="font-16 text-uppercase">NET WORKER II - SOLUÇÕES INFORMÁTICAS</p>
                                    <h1 className="hero-title mb-4">Comece hoje a&nbsp;  
					<TextLoop>    
						<div> <span id="palavra2" className="text-success"> planear</span></div>
						<div> <span id="palavra2" className="text-success"> rentabilizar</span></div>
						<div><span id="palavra2" className="text-success"> ganhar</span></div>
					</TextLoop>
				    </h1>

                                    <p>Há 20 anos a encontrar as melhores soluções informáticas para sua empresa.</p>



                                
                                         <div className="mt-4">
                                        <a href="#servicos" className="btn btn-success mt-2 mr-2">Os nossos serviços</a>
                                       <a href="#contactos" className="btn btn-primary mt-2 mr-2">Contactos</a>
                                    </div>


                            

                                </div>

                            </Col>

                            <Col lg={6} sm={8}>
                                <div className="home-img mt-5 mt-lg-0">
                                    <img src={homeImg} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;