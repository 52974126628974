import React, { Component,useState,useRef } from 'react';
import  ReactDOM  from  'react-dom';
import { SocialIcon } from 'react-social-icons';
import DarkModeToggle from "react-dark-mode-toggle";
import 'react-responsive-modal/styles.css';
import { Link } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import emailjs from 'emailjs-com';
import {
    Navbar,
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Container,
    Collapse,
    Button
} from "reactstrap";

import ScrollspyNav from "./scrollSpy";

//Import Images
import logolight from "../../assets/images/logo-dark.png";
import logodark from "../../assets/images/logo-dark.png";



const App = () => {

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
	
  function assistencia() {
    var pincode = document.getElementById('pincode').value;
	if (pincode === ""){
		alert("Ocorreu um erro!\nPor favor preencha o campo código. (Obrigatório)");
		}else if (pincode.length === 9){
	
	var url = "https://www.startcontrol.com/pin.php?pincode="+pincode;
	window.open(url,'_blank'); 
	}else{
		alert("Ocorreu um erro!\nO código não pode conter mais que 9 dígitos.");
	}}
	
	
	
  return (

    <div>
      <Button onClick={onOpenModal} target="_blank" color="success" className="btn-rounded navbar-btn ">Suporte</Button>						
      <Modal open={open} onClose={onCloseModal} center>
          <center><h5 className="text-center">Assistência Técnica</h5><p></p>
		  <hr/>
		  <a href="tel:255866351" className="text-primary text-uppercase small-title"><img style={{width:25, paddingBottom:5, paddingRight:5 }} src="https://www.ceblueanguilla.com/wp-content/uploads/2015/05/1432234332_phone.png"></img> 255 866 351</a> <p/>
		  <p class="text-primary text-uppercase small-title"><img style={{width:25, paddingBottom:4, paddingRight:5 }} src="https://svg-clipart.com/clipart/symbol/rPBs5WM-mail-symbol-grey-clipart.png"></img><a href="mailto:depcomercial@networker.pt">depcomercial@networker.pt</a></p>
		 <App2/>
		  
		  <hr/>
		  <h6 className="text-center">Assistência Remota</h6>
		  <p>Por favor introduza abaixo o código fornecido pelo técnico</p><p></p>
		  
        <input placeholder="123 456 789"  style={{fontSize: "24px", textAlign:"center"}} id="pincode" class="subscribe-container__email" type="number"></input><p></p>
		<p style={{ paddingTop:0, fontSize: "12px" }} >Após clicar no botão o download será iniciado automaticamente</p>
       <p><Button color="success" type="submit" onClick={assistencia}  className="btn-rounded navbar-btn ">Descarregar</Button>	</p>
	  
	    <p style={{ paddingTop:10,fontSize: "12px" }} >Execute o ficheiro e aguarde que um técnico estabeleça ligação</p>
	 
	   </center>
      </Modal>
    </div>
  );
  
		
};


const App2 = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('networker', 'template_bxzubgh', form.current, 'pmN4q5otzH8gNukEz')
      .then((result) => {
          alert('Pedido de assistência registado com sucesso.');
		  window.location.reload(true);
		  
      }, (error) => {
          console.log(error.text);
      });
  };	
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
 
    <div >
	  <p class="text-primary text-uppercase "><a class="pointer" onClick={onOpenModal}><u>REGISTAR PEDIDO DE ASSISTÊNCIA</u></a></p>
      <Modal open={open} onClose={onCloseModal} center><p></p><p></p>
	
        <h5 className="text-center" >Registar Pedido de Assistência</h5><p></p>
		<p></p>
		<hr/><p></p>
		<form ref={form} onSubmit={sendEmail}>
		<div  class="linhas" >
		
	
		<input  name="nif"  placeholder="Nº de identificação fiscal"  style={{fontSize: "12px", textAlign:"left"}} id="nif" type="number" class="subscribe-container" required></input><div style={{width: "10px"}}></div>
		<input name="email" placeholder="E-mail"  style={{fontSize: "12px", textAlign:"left"}} id="email" type="email" class="subscribe-container" required></input>		
		

		
		</div>
		
		
		<div  class="linhas" >
		<input  placeholder="Nome" name="nome"  style={{fontSize: "12px", textAlign:"left"}} id="nome" class="subscribe-container" required></input>

			<div style={{width: "10px"}}></div>

		<input name="telefone" placeholder="Telefone"  style={{fontSize: "12px", textAlign:"left"}} id="telefone" type="number" class="subscribe-container" required></input>
		
		</div>
	
		<textarea name="message"   cols="40" rows="5" placeholder="Mensagem"  style={{fontSize: "12px", textAlign:"left"}} id="message" class="subscribe-container" type="text" required></textarea >
	
	<center>
		
		<p></p>
		
		
		
		 <p><Button color="success" type="submit" className="btn-rounded navbar-btn ">Registar pedido</Button>	</p>
		</center>
		
	</form>

      </Modal>
	  
    </div>
	
  );
};

	
class NavbarPage extends Component {
	
	
    constructor(props) {
        super(props);
        this.state = {
            isOpenMenu: false
        };
    }

    toggle = () => {
        this.setState({ isOpenMenu: !this.state.isOpenMenu });
    }

    render() {
        //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
        let targetId = this.props.navItems.map((item) => {
            return (
                item.idnm
            )
        });
		
		
        return (
		
            <React.Fragment>
                <Navbar expand="lg" fixed="top" className={"navbar-custom sticky sticky-dark " + this.props.navClass}>
                    <Container>
                        
                        <NavbarBrand className="logo text-uppercase" href="/">
                            {
                                this.props.imglight === true ?
                                    <img src={logodark} alt="" className="logo-dark" height="50" />
                                    : <img src={logodark} alt="" className="logo-dark" height="50" />
                            }
                        </NavbarBrand>
		
						

                        <NavbarToggler onClick={this.toggle} ><i className="mdi mdi-menu"></i></NavbarToggler>

                        <Collapse id="navbarCollapse" isOpen={this.state.isOpenMenu} navbar>
                            <ScrollspyNav
                                scrollTargetIds={targetId}
                                scrollDuration="200"
                                headerBackground="true"
                                activeNavClass="active"
                                className="navbar-collapse"
                            >
                                <Nav navbar className="ml-auto navbar-center" id="mySidenav">
                                    {this.props.navItems.map((item, key) => (
                                        <NavItem key={key} className={item.navheading === "Home" ? "active" : ""}>
                                            <NavLink href={"#" + item.idnm} > {item.navheading}</NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                           	    



                            

                                 </ScrollspyNav>
								
					
								 
                                 <App/>
								 
                                   <div class="middle">
                                <div class="teste">
                              <a class="btnfacebook"  target="_blank" href="https://www.facebook.com/Net-Worker-250672194961558">
                              <SocialIcon network="facebook" fgColor="#ffffff"  />

                              </a>
                              <a class="btntwitter"  target="_blank" href="https://linkedin.com/in/networker-pt">
                                 <SocialIcon network="linkedin"  fgColor="#ffffff" />
                              </a>
                             </div>
                            
			</div>  
                        </Collapse>
                       
                    </Container>
                </Navbar>

            </React.Fragment>
        );
    }
}


export default NavbarPage;