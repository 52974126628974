import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : [
                { icon : "globe", title : "Alojamento e manutenção web", desc : "Os melhores alojamentos web para que nunca fique offline." },
                { icon : "command", title : "Software", desc : "Software licenciados e atualizados para trabalhar sem problemas." },
                { icon : "settings", title : "Manutenção", desc : "Máquinas, servidores, backups. Mantenha a sua empresa sempre a 100%." },
                { icon : "wifi", title : "Redes", desc : "Para garantir que está sempre online." },
                { icon : "code", title : "Programação", desc : "Programas especialmente desenhados para as suas necessidades." },
                { icon : "tablet", title : "Formação", desc : "Formação de utilizadores nas mais diversas áreas." },
            ]
        }
    }
    
    render() {
        return (
            
            <React.Fragment>
            <div id="servicos">
        <section className="section bg-light" id="services">
            <Container>
            <SectionTitle
                subtitle="Serviços"
                title="Os nossos serviços"
                desc="Oferecemos diversos serviços para garantir controlo e qualidade de trabalho"
            />

                <Row>
                    <ServiceBox services={this.state.services} />
                </Row>
                

              
                
            </Container>
            
        </section></div>
            </React.Fragment>
        );
    }
}

export default Process;