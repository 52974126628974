import React, { Component,useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import FeatherIcon from 'feather-icons-react';
import  ReactDOM  from  'react-dom';
import { SocialIcon } from 'react-social-icons';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
//Import Footer link
import FooterLinks from "./footer-links";
const App = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <Link class="mb-4 footer-list-custom" onClick={onOpenModal}>Política de privacidade</Link>
      <Modal open={open} onClose={onCloseModal} center>
        <h2 className="text-center">Política de privacidade</h2>
        <p>Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível.</p>
        <p>A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a Net Worker.
Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a Net Worker serão tratadas em concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro de 1998 (Lei n.º 67/98).</p>
      <p>
        A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel, morada, data de nascimento e/ou outros.

      </p>

    <p>
O uso da Net Worker pressupõe a aceitação deste Acordo de privacidade. A equipa da Net Worker reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.
    </p>

<p>Utilizamos cookies para armazenar informação, tais como as suas preferências pessoas quando visita o nosso website. Isto poderá incluir um simples popup, ou uma ligação em vários serviços que providenciamos, tais como fóruns.</p>
<p>Em adição também utilizamos publicidade de terceiros no nosso website para suportar os custos de manutenção. Alguns destes publicitários, poderão utilizar tecnologias como os cookies e/ou web beacons quando publicitam no nosso website, o que fará com que esses publicitários (como o Google através do Google AdSense) também recebam a sua informação pessoal, como o endereço IP, o seu ISP, o seu browser, etc. Esta função é geralmente utilizada para geotargeting (mostrar publicidade de Lisboa apenas aos leitores oriundos de Lisboa por ex.) ou apresentar publicidade direcionada a um tipo de utilizador (como mostrar publicidade de restaurante a um utilizador que visita sites de culinária regularmente, por ex.).</p>
<p>Você detém o poder de desligar os seus cookies, nas opções do seu browser, ou efetuando alterações nas ferramentas de programas Anti-Virus, como o Norton Internet Security. No entanto, isso poderá alterar a forma como interage com o nosso website, ou outros websites. Isso poderá afetar ou não permitir que faça logins em programas, sites ou fóruns da nossa e de outras redes.</p>

      </Modal>
    </div>
  );
};

const App2 = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <Link class="mb-4 footer-list-custom" onClick={onOpenModal}>RAL e RLL</Link>
      <Modal open={open} onClose={onCloseModal} center>
        <h2 className="text-center">RAL e RLL</h2>
        <p>
<h3>RAL</h3>

Em caso de litígio o consumidor pode recorrer a uma entidade de Resolução Alternativa de Litígios de Consumo (RAL). As entidades de Resolução Alternativa de Litígios de Consumo (RAL) são as entidades autorizadas a efetuar a mediação, conciliação e arbitragem de litígios de consumo em Portugal que estejam inscritas na lista de entidades RAL prevista pela Lei n.º 144/2015.
<p>Clique em <a href="www.ipai.pt/fotos/gca/i006245_1459446712.pdf">www.ipai.pt/fotos/gca/i006245_1459446712.pdf</a></p></p>
<p>
<h3>RLL</h3>
O consumidor pode recorrer à plataforma europeia de resolução de litígios em linha disponível em <a href="https:\\ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=PT">ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=PT</a>
</p>
        

      </Modal>
    </div>
  );
};

const App3 = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <Link class="mb-4 footer-list-custom" onClick={onOpenModal}>Política de cookies</Link>
      <Modal open={open} onClose={onCloseModal} center>
        <h2 className="text-center">Política de cookies</h2>
        <h3>O Que É Um Cookie?</h3>

<p>Os cookies são pequenos ficheiros de texto ou peças de informação que são armazenados no computador ou dispositivo móvel (como um smartphone ou tablet) do utilizador, quando este visita os nossos Sites. Um cookies geralmente contêm o nome do website de origem desse cookie, o "tempo de vida" do cookies (ou seja, quanto tempo o cookies permanecerá no dispositivo do utilizador), e um valor, que normalmente é um número exclusivo gerado aleatoriamente.</p>

<h3>Para Que Usamos Os Cookies?</h3>

<p>Nós usamos cookies para tornar os nossos Sites mais fáceis de usar, e para melhor adequar os Sites e os nossos produtos aos interesses e necessidades do utilizador. Os cookies ajudam a fazê-lo, na medida em que são arquivos que os sites conseguem ler e executar, permitindo-lhes reconhecer o utilizador e lembrar-se de informações importantes que irão tornar a utilização desses sites mais conveniente (por exemplo, lembrando as configurações de preferência dos utilizadores).</p>
<p>
Os cookies podem ser igualmente usados para ajudar a acelerar as actividades e experiências futuras dos utilizadores nos nossos Sites. Também usamos cookies para compilar estatísticas anónimas e agregadas, que nos possibilitam compreender a forma como os utilizadores utilizam os nossos Sites, o que nos ajuda a melhorar a estrutura e o conteúdo dos mesmos.
</p>
<h3>Que Tipos de Cookies Utilizamos?</h3>

<p>Nos nossos Sites, utilizamos dois tipos de cookies: os chamados "cookies de sessão" e "cookies persistentes". Os cookies de sessão são cookies temporários, que permanecem no dispositivo do utilizador até este deixar o Site. Os cookies persistentes permanecem no dispositivo por muito mais tempo, ou até que sejam excluídos manualmente pelo utilizador (o tempo de permanência deste tipo de cookies nos dispositivos vai depender da duração ou da "vida" do cookie específico).</p>
<h3>Contacte-nos</h3>

<p>Caso tenha alguma questão acerca desta Política de Cookies, por favor contacte-nos.</p>
        

      </Modal>
    </div>
  );
};


class Footer extends Component {
    state = {
        links1 : [
            { link : "politica-privacidade", title : "Política de privacidade"},
            { link : "#", title : "RGPD"}
          
        ],
        links2 : [
           
        ],
    }
 


    render() {
        return (

            <React.Fragment>

            <div id="contactos">
        <footer id="footer" className="footer">
            <Container>
                <Row>
                    <Col lg={4} sm={6} >
                        <div>
                            <h5 className="mb-4 footer-list-title">Sobre a Net Worker</h5>
                            <p>Fundada em 1998, dedica-se a apoiar as pequenas e médias empresas nas suas mais diversas necessidades tecnológicas.  </p>
                        </div>
                     
                              <a class="btnfacebookfooter" target="_blank" href="https://www.facebook.com/Net-Worker-250672194961558">
                            <SocialIcon network="facebook" bgColor="#01012d" fgColor="#ffffff" />
                              </a>
                                <a class="btnfacebookfooter" target="_blank" href="https://linkedin.com/in/networker-pt">
                            <SocialIcon network="linkedin" bgColor="#01012d" fgColor="#ffffff" />
                              </a>
                             
                    
                    </Col>

                    <Col lg={{size:3, offset:1}} sm={6}>
                        <div>
                            <h5 className="mb-4 footer-list-title">Páginas</h5>
                            <ul className="list-unstyled footer-list-menu">
                        
                           <App />
				<App3/>
                            <App2/>
				
				
                            </ul>

                        </div>
                    </Col>
                   


                    <Col lg={4} sm={6}>
                        <div>
                            <h5 className="mb-4 footer-list-title">Contactos</h5>

                            <div>
                                <Media>
                                    <i>
                                    <a target="_blank" href="https://www.google.com/maps/place/Net+Worker+Ii+-+Solu%C3%A7%C3%B5es+Inform%C3%A1ticas,+Lda./@41.280776,-8.3696044,15z/data=!4m2!3m1!1s0x0:0x4df784e3df54dc20?sa=X&ved=2ahUKEwjq8dLBxfnxAhVRolwKHWU-BiMQ_BIwCnoECDYQBQ"><FeatherIcon icon="map-pin" className="icon-dual-light icons-sm mt-1 mr-2" /></a>
                                    </i>
                                    <Media body>
									<a style={{ color:"#ffffff",opacity:0.8 }} target="_blank" href="https://www.google.com/maps/place/Net+Worker+Ii+-+Solu%C3%A7%C3%B5es+Inform%C3%A1ticas,+Lda./@41.280776,-8.3696044,15z/data=!4m2!3m1!1s0x0:0x4df784e3df54dc20?sa=X&ved=2ahUKEwjq8dLBxfnxAhVRolwKHWU-BiMQ_BIwCnoECDYQBQ">
                                        <p>Av. João XXIII, nº123, 2º andar, Sala 6</p>
					<p>4590-515 Paços de Ferreira, Portugal</p>
									</a>
                                    </Media>
                                </Media>
                                <Media>
                                    <i>
                                    <a href="mailto:depcomercial@networker.pt">
                                    <FeatherIcon icon="mail" className="icon-dual-light icons-sm mt-1 mr-2" />
                                    </a>
                                    </i>
                                    <Media body>
									<a style={{ color:"#ffffff"  ,opacity:0.8}} href="mailto:depcomercial@networker.pt">
                                        <p>depcomercial@networker.pt</p>
									</a>
                                    </Media>
                                </Media>
                                <Media>
                                    <i>
                                    <a href="tel:255866351">
                                    
									<FeatherIcon icon="phone" className="icon-dual-light icons-sm mt-1 mr-2" />
                                    </a>
                                    </i>
                                    <Media body>
                                       	<a style={{ color:"#ffffff"  ,opacity:0.8}} href="tel:255866351">
									   <p>255 866 351</p>
									   </a>
                                    </Media>
                                </Media>
				
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
                {/* Render footer links */}
                <FooterLinks/>
          </div>
            </React.Fragment>
        );
    }
}

export default Footer;