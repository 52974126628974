import React, { Component } from 'react';
import { Container, Row, Col, Form, Input, Button } from "reactstrap";

import 'react-mailchimp-email-signup-form/dist/esm/index.css';
import { ReactMailchimpEmailSignupForm } from 'react-mailchimp-email-signup-form';
const url = "//networker.us18.list-manage.com/subscribe/post?u=469991077f4894f35a1dd299c&amp;id=fd85e1279b";
class Subscribe extends Component {
    render() {
        return (
            <React.Fragment>
        <section className="section">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div className="text-center mb-5">
                            <h5 className="text-primary text-uppercase small-title">NEWSLETTER</h5>
                            <h4 className="mb-3">Subscreva á nossa Newsletter</h4>
                            <p>Receba sempre as últimas novidades, promoções e notícias </p>
                        </div>
                    </Col>

                    <Col xl={7} lg={12}>
                        <div className="text-center">
                           
                                
                                  
                                          
                                          
                                          
<ReactMailchimpEmailSignupForm
    elementId="first-email-signup-form"
    url="https://networker.us18.list-manage.com/subscribe/post?u=469991077f4894f35a1dd299c&amp;id=fd85e1279b"
  
   
  />  
                                          
   


                                        
                               
                             
                           
                        </div>
                    </Col>
                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Subscribe;