import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Import Section Title
import SectionTitle from "../common/section-title";
import Counter from "../../components/Counter/counter";
import ClientBox from "./client-box";

//Import Images
import clinet1 from "../../assets/images/clients/1.png";
import clinet3 from "../../assets/images/clients/3.png";
import clinet4 from "../../assets/images/clients/4.png";
import clinet6 from "../../assets/images/clients/6.png";
import clinet5 from "../../assets/images/clients/5.png";
import clinet7 from "../../assets/images/clients/7.png";


class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [
                { name: "Henry McElyea", post: "Invoza User", desc: "Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary. The languages only differ in their most common words." },
                { name: "Timothy Fairley", post: "Invoza User", desc: "To achieve this, it would be necessary to have uniform grammar, pronunciation and more common words. If several languages of the resulting language" },
                { name: "James Brown", post: "Invoza User", desc: "Everyone realizes why a new common language would be desirable: one could refuse to pay expensive translators. To achieve this it would be necessary." },
                { name: "Jason Davis", post: "Invoza User", desc: "Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary. The languages only differ in their most common words." },
                { name: "Rodney Tyler", post: "Invoza User", desc: "For science, music, sport, etc, Europe uses the same vocabulary. The languages only differ in their grammar, their pronunciation and their most common words." },
           
		   ],
            clients2: [
                { image: clinet1 },
                { image: clinet3 },
                { image: clinet4 },
                { image: clinet6 },
				{ image: clinet5 },
				{ image: clinet7 },
            ],
            responsive: {
                576: {
                    items: 2
                },
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="clients">
                    <Container>
                        <SectionTitle
                            subtitle="Parceiros"
                            title="Parceiros Certificados"
                            desc=""
                        />

                        <Row className="mt-5">
                            
                                
                                    <Col xl={2} sm={6} >
                                        <div className="client-images">
                                            <a target="_blank" href="https://phcsoftware.com/"><img src={clinet1} alt="PHC Software" className="mx-auto img-fluid d-block" /></a>
											   
                                        </div>
										
                                    </Col>
									

									  <Col xl={2} sm={6} >
									  <div className="client-images">
									 <a target="_blank" href="https://nexus-solutions.pt/"><img src={clinet3} alt="Nexus Solutions" className="mx-auto img-fluid d-block" /></a>
									 </div>
									 </Col>
									<Col xl={2} sm={6} >
									<div className="client-images">
									<a target="_blank" href="https://www.wavesolutions.pt/"><img src={clinet4} alt="Wave Solutions" className="mx-auto img-fluid d-block" /></a>
									</div>
									</Col>
									<Col xl={2} sm={6} >
									<div className="client-images">
									<a target="_blank" href="https://www.microsoft.com"><img src={clinet5} alt="Microsoft" className="mx-auto img-fluid d-block" /></a>
									</div>
									</Col>
									<Col xl={2} sm={6} >
									<div className="client-images">
									<a target="_blank" href="https://www.soon.pt/"><img src={clinet6} alt="Soon Business Solutions" className="mx-auto img-fluid d-block" /></a>
									</div>
									</Col>
									<Col xl={2} sm={6} >
									<div className="client-images">
									<a target="_blank" href="https://www.wps.pt/"><img src={clinet7} alt="We Provide Solutions" className="mx-auto img-fluid d-block" /></a>
									</div>
									</Col>
                            
                        </Row>

                    </Container>

                </section>

                <section className="section bg-primary">
                    <Container>
                        <Row className="justify-content-center mb-5">
                            <Col lg={7}>
                                <div className="text-center text-white-50">
                                    <h4 className="text-white">Soluções personalizadas para o seu negócio</h4>
                                    <p>Mostre-nos a sua ideia, nós tornámo-la uma realidade</p>
                                </div>
                            </Col>
                        </Row>

                        <Counter />
                    </Container>

                </section>
            </React.Fragment>
        );
    }
}

export default Clients;