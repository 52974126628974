import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
   





  <section class="page_404">
  <div class="container">
    <div class="row"> 
    <div class="col-sm-12 ">
    <div class="col-sm-10 col-sm-offset-1  text-center">
    <div class="four_zero_four_bg">
      <h1 class="text-center ">404</h1>
    
    
    </div>
    
    <div class="contant_box_404">
    <h3 class="h2">
    Pareces um pouco perdido
    </h3>
    
    <p class="text_404">A página que procuras não está disponível</p>
    
    <a href="/" class="link_404">Voltar ao website</a>
  </div>
    </div>
    </div>
    </div>
  </div>
</section>




















  </div>
);

export default NotFound;